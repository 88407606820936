<template>
  <div class="download-template">
    <div class="download-title">
      <h3>Download Data SANTANU</h3>
    </div>
    <div class="download-box">
      <div class="list-radar">
        <select name="radars" class="select-radar" :class="{ 'disable': waitingResponseAPI }" @change="pilihArea($event)">
          <option selected disabled>-- Silahkan pilih radar --</option>
          <option
            v-for="radar in radarAktif"
            :key="radar['radar_id']"
            :value="radar['radar_id']"
          >
            {{ radar['radar_name'] }}
          </option>
        </select>
      </div>
      <div class="history-datetime">
        <Datepicker
          v-model="pilihanWaktu"
          @update:modelValue="submitPermission()"
          class="datepicker-form"
          :class="{ 'disable': waitingResponseAPI }"
          :clearable="false"
          :time-picker-component="timePicker"
          :minDate="new Date(2020, 12)"
          :maxDate="new Date()"
          :format="format"
          :previewFormat="format"
          cancelText="Batal"
          selectText="Pilih"
        />
      </div>
      <button class="btn btn-preview btn-animated" @click="previewDataHistory()" :class="{ 'btn-disabled': !dapatPreviewHistory }">Submit</button>
      <button class="btn btn-history btn-animated" :class="{ 'btn-disabled': !dapatDownload }" @click="submitWaktuHistoryTerpilih()">Download</button>
    </div>
    <div class="error-message">
      <p ref="errorMessage"></p>
    </div>
    <div class="history-image">
      <div v-for="(item, index) in listPathGambar" :key="index" class="radar-image">
        <v-lazy-image :src="item.path" />
        <h5>{{ item.datetime }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, defineAsyncComponent } from 'vue'
import axios from 'axios'
import moment from 'moment'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import VLazyImage from "v-lazy-image"

const TimePicker = defineAsyncComponent(() => import('../components/settings/TimePickerCustom.vue'))

export default {
  components: { Datepicker, "v-lazy-image": VLazyImage },
  setup() {
    const store = useStore()

    const radarAktif = ref(null)
    
    axios.get('/radar-location')
      .then(({ data }) => {
        radarAktif.value = data
        store.dispatch('updateDefaultRadarAktif', data)
      }).catch(() => {
        radarAktif.value = store.getters.defaultRadarAktif
      })

    const radarTerpilih = ref()

    const pilihArea = (event) => {
      if (radarTerpilih.value == event.target.value) return
      radarTerpilih.value = event.target.value
      dapatPreviewHistory.value = true
      dapatDownload.value = false
    }

    const pilihanWaktu = ref(new Date())

    const tahun = ref(pilihanWaktu.value.getUTCFullYear())
    const bulan = ref(pilihanWaktu.value.getUTCMonth() + 1)
    const tanggal = ref(pilihanWaktu.value.getUTCDate())
    const jam = ref(pilihanWaktu.value.getUTCHours())
    const zonaWaktu = ref(encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone))
    const strWaktu = ref()

    const format = (date) => {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()

      return `${day}/${month}/${year} ${hour}:00`
    }

    const timePicker = computed(() => TimePicker)

    const dapatPreviewHistory = ref(true)
    const dapatDownload = ref(false)

    const submitPermission = () => {
      if (strWaktu.value == pilihanWaktu.value.toISOString()) return

      tahun.value = pilihanWaktu.value.getUTCFullYear()
      bulan.value = pilihanWaktu.value.getUTCMonth() + 1
      tanggal.value = pilihanWaktu.value.getUTCDate()
      jam.value= pilihanWaktu.value.getUTCHours()

      strWaktu.value = `${pilihanWaktu.value.toISOString()}`

      dapatPreviewHistory.value = true
      dapatDownload.value = false
    }

    const waitingResponseAPI = ref(false)

    const listPathGambar = ref([])

    const previewDataHistory = () => {
      dapatPreviewHistory.value = false
      waitingResponseAPI.value = true
      listPathGambar.value = []

      if (radarTerpilih.value == null) {
        waitingResponseAPI.value = false
        errorMessage.value.innerText = 'Silahkan pilih radar'
        return
      }

      if (pilihanWaktu.value == null) {
        errorMessage.value.innerText = 'Silahkan pilih tanggal dan waktu'
        return
      }

      errorMessage.value.innerText = "Loading..."

      axios({
        url: `/download-path?radar=${radarTerpilih.value}&tahun=${tahun.value}&bulan=${bulan.value}&tanggal=${tanggal.value}&jam=${jam.value}`,
        method: 'GET',
        // responseType: 'application/json'
      })
      .then(({ data }) => {
        dapatDownload.value = true
        const lenPathGambar = data.length
        for (let item = 0; item < lenPathGambar; item++) {
            let pathGambar = "/api/dbz" + data[item].path + '/' + data[item].filename.slice(0,-4)
            data[item].path = pathGambar
            data[item].datetime = moment.utc(data[item].datetime).local().format('D MMMM YYYY, HH:mm')
        }
        listPathGambar.value = data
        errorMessage.value.innerText = "Data ditemukan"
        waitingResponseAPI.value = false
      })
      .catch(({ response }) => {
        if (response.status == 422) errorMessage.value.innerText = 'Silahkan pilih radar'
        if (response.status == 404) errorMessage.value.innerText = 'Data tidak ditemukan'
        waitingResponseAPI.value = false
      })
    }

    const submitWaktuHistoryTerpilih = () => {
      dapatPreviewHistory.value = false
      dapatDownload.value = false
      waitingResponseAPI.value = true

      errorMessage.value.innerText = "Mengunduh data..."
      
      if (pilihanWaktu.value == null) return

      let waktuTerpilih = moment(pilihanWaktu.value).format("YYYYMMDDTHH00")

      axios({
        url: `/download?radar=${radarTerpilih.value}&tahun=${tahun.value}&bulan=${bulan.value}&tanggal=${tanggal.value}&jam=${jam.value}&zona=${zonaWaktu.value}`,
        method: 'GET',
        responseType: 'blob'
      })
      .then(({ data }) => {
        errorMessage.value.innerText = "Data ditemukan"
        waitingResponseAPI.value = false

        const filename = radarAktif.value.find(x => x.radar_id == radarTerpilih.value).radar_location_city.split(' ').join('')
        
        // create file link in browser's memory
        const href = URL.createObjectURL(data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute(
          'download',
          `${filename}_${waktuTerpilih}.zip`
        ) //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        dapatDownload.value = true
        errorMessage.value.innerText = "Selesai"
      })
      .catch(({ response }) => {
        if (response.status == 422) errorMessage.value.innerText = 'Silahkan pilih radar'
        if (response.status == 404) errorMessage.value.innerText = 'Data tidak ditemukan'
        waitingResponseAPI.value = false
      })
    }

    const errorMessage = ref(null)

    return {
      radarAktif,
      pilihArea,
      pilihanWaktu,
      format,
      timePicker,
      dapatPreviewHistory,
      dapatDownload,
      submitPermission,
      waitingResponseAPI,
      listPathGambar,
      previewDataHistory,
      submitWaktuHistoryTerpilih,
      errorMessage
    }
  }
}
</script>

<style>
div .disable {
  pointer-events: none;
}

.download-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 100%;
  background-color: #FCFAF9;
  font-size: 1.4rem;
}

.download-template .download-title {
  padding: 1.5rem 0;
}

.download-template .download-title h3 {
  font-size: 1.8rem;
  color: #107c5c;
}

.download-template .error-message {
  margin: 2rem 0;
}

.download-template .error-message p {
  text-align: center;
}

.download-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.list-radar {
  position: relative;
  display: flex;
  justify-content: center;
  height: 2.7rem;
  /* min-width: 15rem; */
  /* line-height: 2; */
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  border-radius: 5px;
  overflow: hidden;
}

.list-radar::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-25%, 50%);
  cursor: pointer;
  pointer-events: none;
  transition: .25s all ease;
}

.list-radar:hover::after {
  color: #107c5c;
}

.list-radar select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0;
  background: #fff;
  background-color: #E9FAE3;
  padding: 0 30px 0 15px;
  cursor: pointer;
}

.list-radar select::-ms-expand {
  display: none;
}

.btn-preview {
  /* margin: 1rem 0; */
  text-align: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  background-color: #fcdf6b;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.btn-preview:hover {
  transform: translateY(-3px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.btn-preview:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

.btn-preview.btn-disabled {
  background-color: #fff5ce;
  pointer-events: none;
}

.btn-history {
  /* margin: 1rem 0; */
  text-align: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
  background-color: #20c997;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.btn-history:hover {
  transform: translateY(-3px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.btn-history:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

.btn-history.btn-disabled {
  background-color: #E9FAE3;
  pointer-events: none;
}

.btn-animated {
  transition: all 0.2s;
}

.history-image {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  padding: 0 2rem 2rem 2rem;
  background-color: #FCFAF9;
}

.history-image .radar-image {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid rgba(0,0,0,0.2);
  padding: 0.6rem 0.25rem;
}

.history-image .radar-image h5 {
  text-align: center;
}

.v-lazy-image {
  height: 20rem;
  filter: blur(20px);
  transition: filter 0.7s;
}

.v-lazy-image-loaded {
  filter: blur(0);
}

@media (min-width: 650px) {
  .download-box {
    flex-direction: row;
    gap: 1rem;
  }
}
</style>